import React, { FC } from "react";
import clsx from "clsx";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";

export type BreadCrumbsItem = {
  text: string;
  link?: string;
};

type Props = {
  items: BreadCrumbsItem[];
};

export const BreadCrumbs: FC<Props> = (props) => {
  const { items } = props;
  return (
    <ul className={styles.BreadCrumbs}>
      {items.map((item, index) => {
        const textClassName = clsx(styles.BreadCrumbs_Text, [
          index + 1 === items.length && styles.BreadCrumbs_Text__Current,
        ]);
        return (
          <li key={item.text} className={styles.BreadCrumbs_Item}>
            {item.link ? (
              <Link className={textClassName} to={item.link}>
                {item.text}
              </Link>
            ) : (
              <span className={textClassName}>{item.text}</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};
