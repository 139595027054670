import React, { FC } from "react";
import * as styles from "./index.module.scss";
import {
  BreadCrumbs as BreadCrumbsComponent,
  BreadCrumbsItem,
} from "~components/utils/bread-crumbs";

type Props = {
  title: string;
};

export const BreadCrumbs: FC<Props> = (props) => {
  const { title } = props;

  const breadCrumbsItems: BreadCrumbsItem[] = [
    {
      text: "トップ",
      link: "/",
    },
    {
      text: "セキュリティ診断",
      link: "/assessment",
    },
    {
      text: title,
    },
  ];

  return (
    <div className={styles.BreadCrumbs}>
      <BreadCrumbsComponent items={breadCrumbsItems} />
    </div>
  );
};
