import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import { ArticleLink } from "../article-link";

import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { Section } from "~components/templates/section";

type ContainerProps = {
  data: {
    articles: GatsbyAssessmentArticleListItemDataFragment[];
    category?: Maybe<GatsbyAssessmentArticlaListCategoryFragment>;
  };
};

type Props = {
  booksIcon: GatsbyAssessmentArticleListComponentQuery["booksIcon"];
} & ContainerProps;

const Component = (props: Props) => {
  const { data } = props;

  if (data.articles.length === 0) return null;

  return (
    <Section background="flatt-red-light">
      <Common2PaneSectionLayout>
        {data.category !== undefined && (
          <DocumentStyle width="no-limit">
            <h2>
              {data.category?.name}の<span>コラム記事</span>
            </h2>
          </DocumentStyle>
        )}
        <div className="h-6" />
        <div className="flex justify-center">
          <ul className="grid grid-cols-1 gap-4 tablet-smallpc:grid-cols-2">
            {data.articles.map((article) => {
              return (
                <li key={article.title}>
                  <ArticleLink
                    title={article.title ?? ""}
                    description={article.description ?? ""}
                    href={`/articles/${article.slug}`}
                    className="p-8 bg-white rounded-default h-full tablet-smallpc:w-full"
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </Common2PaneSectionLayout>
    </Section>
  );
};

export const AssessmentArticleList = (props: ContainerProps) => {
  const data = useStaticQuery(graphql`
    query AssessmentArticleListComponent {
      booksIcon: file(relativePath: { eq: "books.svg" }) {
        publicURL
      }
    }
  `);

  return <Component booksIcon={data.booksIcon} data={props.data} />;
};

export const query = graphql`
  fragment AssessmentArticleListItemData on ContentfulArticle {
    id
    description
    updatedAt
    title
    slug
  }

  fragment AssessmentArticlaListCategory on ContentfulArticleCategory {
    name
  }
`;
