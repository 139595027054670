// extracted by mini-css-extract-plugin
export var VoiceThumbnail = "index-module--VoiceThumbnail--pClOn";
export var VoiceThumbnail_About = "index-module--VoiceThumbnail_About--WWTJY";
export var VoiceThumbnail_Avatar = "index-module--VoiceThumbnail_Avatar--lymVq";
export var VoiceThumbnail_AvatarWrapper = "index-module--VoiceThumbnail_AvatarWrapper--1KLrI";
export var VoiceThumbnail_Company = "index-module--VoiceThumbnail_Company--Yi9d+";
export var VoiceThumbnail_Position = "index-module--VoiceThumbnail_Position--+jWKo";
export var VoiceThumbnail_Tag = "index-module--VoiceThumbnail_Tag--gogbh";
export var VoiceThumbnail_Title = "index-module--VoiceThumbnail_Title--NkX4f";
export var VoiceThumbnail__FLATT_WEB = "index-module--VoiceThumbnail__FLATT_WEB--0RiYj";
export var VoiceThumbnail__KENRO = "index-module--VoiceThumbnail__KENRO--ZoxNU";