import React, { useState } from "react";
import * as styles from "./index.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";

export const PriceDocBanner: React.FC = () => {
  const [isVisible, setVisibility] = useState<boolean>(true);
  const removeBanner = () => {
    setVisibility(false);
  };

  const data: GatsbyPriceDocBannerComponentQuery = useStaticQuery(graphql`
    query PriceDocBannerComponent {
      assessment_banner: file(
        relativePath: { eq: "assessment/price_doc_banner.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      close: file(
        relativePath: { eq: "assessment/banner_close.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div
      className={styles.PriceDocBanner}
      style={isVisible ? {} : { display: "none" }}
    >
      <a className={styles.PriceDocBanner_Link} href="/assessment/price_doc">
        <OptionalGatsbyImage
          className={styles.PriceDocBanner_Image}
          data={data.assessment_banner}
          alt=""
        />
      </a>
      <button
        type="button"
        className={styles.PriceDocBanner_DeleteButton}
        id="ts-delete-button"
        onClick={removeBanner}
      >
        <OptionalGatsbyImage
          className={styles.PriceDocBanner_DeleteButtonImage}
          data={data.close}
          alt=""
        />
      </button>
    </div>
  );
};
