import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { VoiceList } from "~components/utils/voice-list";

type Props = {
  slugs?: string[];
};

export const AssessmentVoiceList = ({ slugs }: Props) => {
  const response: GatsbyAsessmentVoiceListComponentQuery = useStaticQuery(
    graphql`
      query AsessmentVoiceListComponent {
        allContentfulVoice(
          filter: { category: { slug: { eq: "assessments" } } }
        ) {
          nodes {
            ...VoiceQuery
          }
        }
      }
    `,
  );

  const data = useMemo(() => {
    return response.allContentfulVoice.nodes.map((node) => {
      return {
        ...node,
        link: `/assessment/voice/${node.slug}`,
      };
    });
  }, [response]);

  return <VoiceList data={data} slugs={slugs} />;
};
