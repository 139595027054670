import React from "react";
import * as styles from "./index.module.scss";

type Props = {
  background?: {
    type: "color" | "image";
    data: string;
  };
  className?: string;
};

export const SectionLayout: React.FC<Props> = (props) => {
  const { children, background } = props;

  if (!background) {
    return <div className={styles.SectionLayout}>{children}</div>;
  }

  switch (background.type) {
    case "color":
      return (
        <div
          className={styles.SectionLayout}
          style={{ backgroundColor: background.data }}
        >
          {children}
        </div>
      );
    case "image":
      return (
        <div
          className={styles.SectionLayout}
          style={{ background: `center / cover url(${background.data})` }}
        >
          {children}
        </div>
      );
    default:
      return <div className={styles.SectionLayout}>{children}</div>;
  }
};
