import React, { useMemo, VFC } from "react";
import {
  GatsbyImage,
  GatsbyImageProps,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image";

type ImageData = {
  childImageSharp?: any;
};

type Props = Omit<GatsbyImageProps, "image"> & {
  data?: ImageData | null;
};

export const OptionalGatsbyImage: VFC<Props> = (props) => {
  const { data, ...gatsbyImageProps } = props;

  const image = useMemo(() => {
    if (data === undefined || data === null) return;
    return getImage(data as ImageDataLike);
  }, [data]);

  if (image === undefined) return null;
  return <GatsbyImage image={image} {...gatsbyImageProps} />;
};
