import React, { FC, useCallback } from "react";

import { Link } from "gatsby";

import { Voice } from "~components/utils/voice";
import type { Theme } from "~utils/theme";
import * as styles from "./index.module.scss";

export type VoiceData = GatsbyVoiceQueryFragment & {
  link: string;
};

type Props = {
  data: VoiceData[];
  slugs?: string[];
  theme?: Theme;
};

export const VoiceList: FC<Props> = (props) => {
  const { slugs, data, theme } = props;

  const filter = useCallback(
    (node: GatsbyVoiceQueryFragment) => {
      if (slugs === undefined) return true;
      return node.slug && slugs.includes(node.slug);
    },
    [slugs],
  );

  const sort = useCallback(
    (a: GatsbyVoiceQueryFragment, b: GatsbyVoiceQueryFragment) => {
      if (a.sortOrder && b.sortOrder) {
        const diff = a.sortOrder - b.sortOrder;
        if (diff !== 0) {
          return diff;
        }
      }
      if (a.date && b.date) {
        return a.date < b.date ? 1 : -1;
      }

      return 0;
    },
    [],
  );

  if (data === undefined) {
    return null;
  }

  return (
    <ul className={styles.VoiceList}>
      {data
        .filter(filter)
        .sort(sort)
        .map(
          (voice) =>
            voice.slug && (
              <li key={voice.slug} className={styles.VoiceList_Item}>
                <Link to={voice.link}>
                  <Voice voice={voice} theme={theme} />
                </Link>
              </li>
            ),
        )}
    </ul>
  );
};
