import React from "react";
import LazyLoad from "react-lazyload";
import { graphql, Link } from "gatsby";

import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import "./index.scss";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import { Button } from "~components/utils/button";
import { Interview } from "~components/utils/interview";
import { AssessmentArticleList } from "~components/articles/assessment-article-list";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { DocumentStyle } from "~components/templates/document-style";
import { TableStyle } from "~components/templates/table-style";

type Props = {
  data: GatsbyAssessmentWebAppPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "スマートフォンアプリ診断",
          description:
            "iOSもしくはAndroidが搭載されたスマートフォンで利用するネイティブアプリケーションおよびAPIに脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。",
        }}
      >
        <div className="SpApp AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop SpAppTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1 className="AssessmentCommonTop_Title">
                      スマートフォンアプリ診断
                    </h1>
                    <p>
                      iOSもしくはAndroidが搭載されたスマートフォンで利用するネイティブアプリケーションおよびAPIに脆弱性がないか網羅的に専門のセキュリティエンジニアが診断します。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="スマートフォンアプリ診断" />

          <LazyLoad>
            <div className="Process">
              <div className="Process_Wrapper">
                <DocumentStyle>
                  <h2>診断の手順</h2>
                </DocumentStyle>
                <ul className="Process_StepList">
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">1</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断準備</h2>
                        <p className="Process_NoteMain">
                          NDA締結と事前ヒアリングを行い最適な診断プランをご提案します。対象のスマートフォンアプリケーションとAPIに関する資料などをベースにお見積りを行います。お見積り内容に同意をいただけましたら契約を締結させていただきます。
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断対象のヒアリング
                            </h4>
                            <p className="Process_NoteNote">
                              弊社が診断対象の仕様を理解するために、ヒアリングを行います。仕様を理解することで、診断・ご提案の正確性の向上に繋がります。対象のサービス概要、規模感、環境、ご希望納期などをお伺いします。
                            </p>
                          </div>
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              お見積もり (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              環境、仕様書、設計書、画面遷移図、URL一覧などの資料をご用意頂きます。こちらを元にリクエスト数や対象の調査をさせていただきます。お見積もりはそのリクエスト数と診断プランをベースに算出します。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">2</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断実施</h2>
                        <p className="Process_NoteMain">
                          お客様の方で診断環境をご用意していただき、対象を診断します。診断結果は診断完了後に報告書にまとめ納品しますが、特に緊急性の高い脆弱性が検出された場合には報告書納品前に簡易的な速報としてご連絡差し上げる事も可能です。
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断環境準備 (診断依頼企業様にて）
                            </h4>
                            <p className="Process_NoteNote">
                              貴社にて診断環境をご用意いただきます。ステージング環境やテスト環境など、本番同様の診断環境をご用意ください。
                            </p>
                          </div>
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              診断開始 (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              セキュリティエンジニアが専用のツールと手動での診断を組み合わせ、スマートフォンアプリケーションを網羅的に診断します。
                            </p>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                    <img
                      className="Process_Triangle"
                      src={data?.triangle?.publicURL || ""}
                      alt=""
                    />
                  </li>
                  <li className="Process_StepWrapper">
                    <div className="Process_Step">
                      <h3 className="Process_Number">3</h3>
                      <div className="Process_Container">
                        <h2 className="Process_SubHeading">診断実施後</h2>
                        <p className="Process_NoteMain">
                          診断が完了いたしましたら診断結果をまとめた報告書をpdfにて納品させていただきます。是非改修にお役立てください。
                        </p>
                        <div className="Process_NoteContainer">
                          <div className="Process_Note">
                            <h4 className="Process_NoteH4">
                              報告書納品 (Flatt Securityにて)
                            </h4>
                            <p className="Process_NoteNote">
                              報告書では診断した項目から対象のセキュリティ対策状況を5段階で評価します。また検出された脆弱性ごとに、達成した攻撃の再現方法、攻撃によるリスクおよび対策方法を詳しく記載します。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout width="large">
                <DocumentStyle width="no-limit">
                  <h2>診断プラン</h2>
                  <p>
                    料金はiOS,Android
                    2OSの場合のものになります。1OSの場合は括弧内の料金が適用されます。
                  </p>
                </DocumentStyle>
                <ul className="Plan_List">
                  <li className="Plan_ListItem">
                    <div className="Plan_ItemHeading">
                      <span className="Plan_RankStars">&#9733;</span>
                      <h3 className="Plan_ItemTitle">Minimum</h3>
                      <div className="Plan_ItemFee">
                        <h4>90万円</h4>
                        <p>(1OS 50万円)</p>
                      </div>
                    </div>
                    <div className="Plan_ItemBody">
                      <p className="Plan_ItemDescription">
                        設定値の確認などの表層的な解析
                        <span className="Plan_Asterisk">*1</span>
                        に加え、一般的に脆弱性が発生しやすい通信やデータの暗号化に関する診断を主に行います。
                      </p>
                      <div className="Plan_Vuln">
                        <h4 className="Plan_VulnHeading">
                          発見される脆弱性の例
                        </h4>
                        <p className="Plan_VulnBody">
                          <ul className="Plan_VulnList">
                            <li>証明書検証不備等による通信の傍受</li>
                            <li>不要な情報の外部送信</li>
                            <li>データの暗号化設定</li>
                          </ul>
                          等
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="Plan_ListItem">
                    <div className="Plan_ItemHeading">
                      <span className="Plan_RankStars">&#9733; &#9733;</span>
                      <h3 className="Plan_ItemTitle">Standard</h3>
                      <div className="Plan_ItemFee">
                        <h4>160万円</h4>
                        <p>(1OS 90万円)</p>
                      </div>
                    </div>
                    <div className="Plan_ItemBody">
                      <p className="Plan_ItemDescription">
                        全てのプランで対象となっている診断項目に加え、悪意を持った第三者による能動的な攻撃の施行※をブラックボックス診断
                        <span className="Plan_Asterisk">*2</span>
                        にて行います。
                      </p>
                      <p className="Plan_ItemDescriptionFootnote">
                        ※攻撃経路となりうる外部連携機能が著しく多い場合には、別途お見積もりが必要となる可能性があります。
                      </p>
                      <div className="Plan_Vuln">
                        <h4 className="Plan_VulnHeading">
                          発見される脆弱性の例
                        </h4>
                        <p className="Plan_VulnBody">
                          <ul className="Plan_VulnList">
                            <li>外部からの攻撃による情報の改竄・漏洩</li>
                            <li>WebView実装不備による機能の不正利用</li>
                            <li>制限付き機能の迂回</li>
                          </ul>
                          等
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="Plan_ListItem">
                    <div className="Plan_ItemHeading">
                      <span className="Plan_RankStars">
                        &#9733; &#9733; &#9733;
                      </span>
                      <h3 className="Plan_ItemTitle">Professional</h3>
                      <div className="Plan_ItemFee">
                        <h4>要見積もり</h4>
                      </div>
                    </div>
                    <div className="Plan_ItemBody">
                      <p className="Plan_ItemDescription">
                        通常(Minimum・Standard)の診断項目に加え、高度な専門知識を有する診断員独自の観点等によって対象のアプリケーションを診断いたします。特殊な診断対象や要望などにも柔軟に対応いたします(ホワイトボックス診断
                        <span className="Plan_Asterisk">*3</span>
                        やリバースエンジニアリング
                        <span className="Plan_Asterisk">*4</span>等)。
                      </p>
                      <div className="Plan_Vuln">
                        <h4 className="Plan_VulnHeading">
                          発見される脆弱性の例
                        </h4>
                        <p className="Plan_VulnBody">
                          <ul className="Plan_VulnList">
                            <li>
                              ホワイトボックス診断で発見する潜在的な脆弱性
                            </li>
                            <li>
                              リバースエンジニアリングで発見する独自仕様の脆弱性
                            </li>
                            <li>ソフトウェアの仕様上の脆弱性</li>
                          </ul>
                          等
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
                <DocumentStyle width="no-limit">
                  <p className="Plan_AsteriskDescription">
                    <span className="Plan_Asterisk">*1</span>
                    iOS/Androidアプリケーションにおいて一般に注意すべきとされている項目(共通のセキュリティ設定や、機能の公開設定等)を確認します。
                    <span className="Plan_Asterisk">*2</span>
                    アプリケーションの内部の挙動を解析せずに、外部から観測できる挙動を元に診断すること。
                    <span className="Plan_Asterisk">*3</span>
                    アプリケーションのソースコード等を元に内部の挙動を含めて診断すること。
                    <span className="Plan_Asterisk">*4</span>
                    ソースコード無しでアプリケーションの内部の挙動を解析すること。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
          <AssessmentDocContact />

          <LazyLoad>
            <Interview title="スマートフォンアプリケーション診断の事例インタビュー">
              <AssessmentVoiceList
                slugs={["mizuhojohosoken", "tameny", "freee", "smartbank"]}
              />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <SubSection>
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h2>診断項目</h2>
                    <p>
                      ツールによる自動診断とセキュリティエンジニアによる高度な手動診断を組み合わせたFlatt
                      Securityの脆弱性診断サービスです。以下の項目を基本として、さらにセキュリティエンジニアの経験・知見を生かして網羅的に診断します。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </SubSection>
              <Common2PaneSectionLayout width="large">
                <TableStyle>
                  <SubSection>
                    <h3>アプリケーション間通信</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>診断項目</th>
                          <th>診断方法</th>
                          <th className="Item_TableHeadingItem3">備考</th>
                          <th className="Item_TableHeadingItem4">プラン</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Activityの不適切な設定</th>
                          <td>
                            Activityが外部に公開されていると、端末内の他のアプリケーションから不正にActivityを利用されるリスクがあります。そのため、不要に公開されてしまっていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>Serviceの不適切な設定</th>
                          <td>
                            Serviceが外部に公開されていると、端末内の他のアプリケーションから不正にServiceを利用されるリスクがあります。そのため、不要に公開されてしまっていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>Content Providerの不適切な設定</th>
                          <td>
                            Content
                            Providerが外部に公開されていると、端末内の他のアプリケーションから不正にContent
                            Providerを利用されるリスクがあります。そのため、不要に公開されてしまっていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>Broadcast Receiverの不適切な設定</th>
                          <td>
                            Broadcast
                            Receiverが外部に公開されていると、端末内の他のアプリケーションから不正にBroadcast
                            Receiverを利用されるリスクがあります。そのため、不要に公開されてしまっていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>重要情報のブロードキャスト</th>
                          <td>
                            ブロードキャストされた情報は端末内の他のアプリケーションに取得される可能性があります。そのため、重要な情報が他のアプリケーションにブロードキャストされてしまっていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>Activityの実装上の不備(intentの取り扱い)</th>
                          <td>
                            Activityが外部からの不正なintentを受け取った際に、情報の漏洩や改竄が発生してしまわないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>Serviceの実装上の不備(intentの取り扱い)</th>
                          <td>
                            Serviceが外部からの不正なintentを受け取った際に、情報の漏洩や改竄が発生してしまわないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>Content Providerの実装上の不備</th>
                          <td>
                            Content
                            Providerが外部からの不正なコンテンツ操作のリクエストを受け取った際に、情報の漏洩や改竄が発生してしまわないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Broadcast Receiverの実装上の不備 (intentの取り扱い)
                          </th>
                          <td>
                            Broadcast
                            Receiverが外部からの不正なintentを受け取った際に、情報の漏洩や改竄が発生してしまわないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>カスタムURLの取り扱いにおける実装上の不備</th>
                          <td>
                            端末内の他のアプリケーション(ブラウザで開かれるWEBアプリケーション含む)からの不正なカスタムURLによって情報が漏洩・改竄されてしまわないかを確認します。
                          </td>
                          <td></td>
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </SubSection>
                  <SubSection>
                    <h3>設計・実装</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>診断項目</th>
                          <th>診断方法</th>
                          <th className="Item_TableHeadingItem3">備考</th>
                          <th className="Item_TableHeadingItem4">プラン</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>WebViewを利用するコードの設計・実装不備</th>
                          <td>
                            WebViewを利用する際、内部プログラムとの連携部分などが適切に実装されていないと、外部のJavaScriptプログラムから内部の機能を悪用され、データの漏洩や改竄に繋がるリスクがあります。そこで、適切に外部プログラムに対する制御がされていることを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>

                        <tr>
                          <th>不要な権限の要求</th>
                          <td>
                            不要に権限を要求すると、アプリケーションが乗っ取られた際のリスクが増大します。また、不要にユーザーの個人情報を収集してるのでは無いかとユーザーからの信頼を失う可能性もあります。そのため、アプリケーションに不要と思われる権限を要求してしまっていないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>データ・ロジック改竄によるアプリの不正利用</th>
                          <td>
                            ローカルに保存されたデータや通信データ等の改竄によって、通常は制限された機能等を不正に利用できてしまわないか確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem Item_ExceptMin">
                            &#9733;&#9733; Std
                            <br />
                            &#9733;&#9733;&#9733; Pro
                          </td>
                        </tr>
                        <tr>
                          <th>アプリケーションへの秘密情報埋め込み</th>
                          <td>
                            アプリケーションファイルに秘密鍵などの本来は埋め込む必要のない情報が埋め込まれていると、攻撃者に漏洩するリスクがあります。そのため、そういった秘密情報が含まれてしまっていないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>不要なデバッグ設定</th>
                          <td>
                            デバッグ機能が有効になっていると、外部からアプリケーションの機能を悪用され、情報が漏洩・改竄されるリスクがあります。そのため、デバッグ機能が無効になっていることを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>不適切なバックアップ設定</th>
                          <td>
                            バックアップ設定が有効になっていると、内部データがクラウド等にバックアップされます。機密データの複製は意図せず漏洩のリスクを増大させるため、過剰なバックアップ設定がされていないかを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_TableRowItem3 Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </SubSection>
                  <SubSection>
                    <h3>解析</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>診断項目</th>
                          <th>診断方法</th>
                          <th className="Item_TableHeadingItem3">備考</th>
                          <th className="Item_TableHeadingItem4">プラン</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>耐タンパー性</th>
                          <td>
                            必要に応じて、アプリケーションの耐タンパー性を評価します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem Item_OnlyPro">
                            &#9733;&#9733;&#9733;
                            <br />
                            Proのみ
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Reverse
                            Engineeringやソースコード解析によるロジックの検査
                          </th>
                          <td>
                            診断員独自の観点によって、内部のロジックなどに含まれた様々な脆弱性を検査します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem Item_OnlyPro">
                            &#9733;&#9733;&#9733;
                            <br />
                            Proのみ
                          </td>
                        </tr>
                        <tr>
                          <th>プロトコル解析</th>
                          <td>
                            独自のプロトコル等を用いている場合、必要に応じてプロトコルを解析します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem Item_OnlyPro">
                            &#9733;&#9733;&#9733;
                            <br />
                            Proのみ
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </SubSection>
                  <SubSection>
                    <h3>データの取り扱い</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>診断項目</th>
                          <th>診断方法</th>
                          <th className="Item_TableHeadingItem3">備考</th>
                          <th className="Item_TableHeadingItem4">プラン</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>不適切なファイル権限</th>
                          <td>
                            外部ストレージなどに保存されたファイルのパーミッションが正しく設定されていないと、端末内の他のアプリケーションから不正に読み書きされるリスクがあります。そのため、正しく設定されていることを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>共有データ領域への重要情報保存</th>
                          <td>
                            外部ストレージ領域等、端末内の他のアプリケーションから読み取れるデータ領域へ重要な情報を保存すると漏洩のリスクがあります。そのため、重要な情報が保存されてしまっていないか確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>
                            アプリケーション用の内部データ領域への重要情報の平文保存
                          </th>
                          <td>
                            アプリケーション固有のデータ領域は基本的に端末内の他のアプリケーションからは読み取れません。しかし、端末紛失時等、スマートフォンのデータを直接読み込まれた際のリスクを低減するために重要な情報は暗号化することが推奨されるため、そのような実装になっていることを確認します。
                          </td>
                          <td className="Item_TableRowItem Item_OnlyAndroid">
                            Android
                            <br />
                            のみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>ログへの重要情報の出力</th>
                          <td>
                            ログへの重要な情報の出力は、アプリケーション外部への情報の漏洩や、攻撃者への解析のヒントとなり得ます。そのため、個人情報や過度なデバッグ情報などがログに流れてしまってしないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>不要になったデータの残存</th>
                          <td>
                            内部外部問わず、重要なデータは不要になったタイミングで端末上から破棄することで漏洩のリスクを低減することが可能です。そのため、端末上に不要になったデータが残存してしまっていないか確認します。
                          </td>
                          <td className="Item_TableRowItem">
                            iOSはProfessionalのみ
                          </td>
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </SubSection>
                  <SubSection>
                    <h3>サーバー通信</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>診断項目</th>
                          <th>診断方法</th>
                          <th className="Item_TableHeadingItem3">備考</th>
                          <th className="Item_TableHeadingItem4">プラン</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>重要情報の平文送信</th>
                          <td>
                            平文通信は信用できないネットワーク(フリーWi-Fi等)において盗聴のリスクがあります。そのため、重要な情報がサーバーとの間で平文で送受信されてしまっていないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>重要情報の外部送信</th>
                          <td>
                            重要情報が、外部(第三者)のサーバーへ意図せず送信されてしまっていないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>SSL/TLS証明書検証の不備</th>
                          <td>
                            適切にSSL/TLS証明書の検証を行わないと、中間者攻撃により盗聴や改竄のリスクがあります。そのため、正しく検証されていることを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                        <tr>
                          <th>不適切なネットワークセキュリティ設定</th>
                          <td>
                            ネットワークセキュリティに関する設定(ATS/networkSecurityConfig)を独自で変更している場合、安全ではない通信が発生するリスクがあります。そのため、そのような設定値になってしまっていないかを確認します。
                          </td>
                          <td />
                          <td className="Item_TableRowItem">
                            全ての
                            <br />
                            プラン
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </SubSection>
                </TableStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>
          <AssessmentArticleList
            data={{
              articles: data.allContentfulArticle.nodes,
              category: data.contentfulArticleCategory,
            }}
          />
          <h2 className="AssessmentCommonContact_Title">
            お見積もりは無料です。診断のご依頼はお気軽にお問い合わせください。
          </h2>
          <Link to="/contact">
            <div className="AssessmentCommonQuickContact">
              <Button buttonTheme="Secondary" buttonSize="Large">
                今すぐお問い合わせ
              </Button>
            </div>
          </Link>
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query AssessmentSpAppPage {
    checkbox: file(relativePath: { eq: "checkbox.png" }) {
      publicURL
    }
    document: file(relativePath: { eq: "document.png" }) {
      publicURL
    }
    triangle: file(relativePath: { eq: "assessment/triangle.png" }) {
      publicURL
    }
    allContentfulArticle(
      filter: { category: { slug: { eq: "sp_app" } }, slug: { ne: "disabled" } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        ...AssessmentArticleListItemData
      }
    }
    contentfulArticleCategory(slug: { eq: "sp_app" }) {
      ...AssessmentArticlaListCategory
    }
  }
`;

export default withContext(Component);
