import React from "react";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";
import clsx from "clsx";

export type Props = {
  title: string;
  description: string;
  href: string;
  className?: string;
};

export const ArticleLink: React.FC<Props> = (props) => {
  const { title, description, href, className } = props;
  return (
    <Link className={styles.ArticleLink} to={href}>
      <div className={className}>
        <div className="flex flex-col text-left">
          <h4 className={clsx("text-flatt-red overflow-hidden", styles.Title)}>
            {title}
          </h4>
          <p className="text-gray overflow-ellipsis overflow-hidden whitespace-nowrap p-1">
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
};
