import React, { FC } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as styles from "./index.module.scss";
import { SectionLayout } from "~components/templates/section-layout";
import { OptionalGatsbyImage } from "~components/utils/optional-gatsby-image";
import { PipeDriveForm } from "~components/utils/pipedrive-form";

export const AssessmentDocContact: FC = () => {
  const CONTACT_FORM_ID =
    "1uMD3hrl5l496OlYEawYvgryrXqwhjr1UxnNIbGBj2a3EQL7wAyiH42ueleZ4voe7";
  const ALTERNATIVE_GOOGLEFORM_URL = "https://forms.gle/B7c1hGh9i4rmJnEg6";

  const data: GatsbyAssessmentDocContactComponentQuery = useStaticQuery(graphql`
    query AssessmentDocContactComponent {
      catch: file(
        relativePath: { eq: "assessment/price_doc_catch.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className={styles.PriceDoc}>
      <div className={styles.PriceDoc_Head}>
        <SectionLayout>
          <OptionalGatsbyImage
            className={styles.PriceDoc_Catch}
            data={data.catch}
            alt=""
          />
          <PipeDriveForm formID={CONTACT_FORM_ID} />
          <p className={styles.PriceDoc_Escape}>
            Internet
            Explorerをお使いの場合など、お問い合わせフォームが表示されない場合は
            <a
              className={styles.PriceDoc_EscapeLink}
              href={ALTERNATIVE_GOOGLEFORM_URL}
            >
              別ページ
            </a>
            からお問い合わせください。
          </p>
        </SectionLayout>
      </div>
    </div>
  );
};
