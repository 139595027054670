import React from "react";
import * as styles from "./index.module.scss";

type Props = {
  className?: string;
};

export const TableStyle: React.FC<Props> = (props) => {
  const { children } = props;

  return <div className={styles.TableStyle}>{children}</div>;
};
