import React, { FC } from "react";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";
import { Button } from "~components/utils/button";
import { DocumentStyle } from "~components/templates/document-style";
import { Section, SubSection } from "~components/templates/section";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";

export type Props = {
  title?: string;
};

export const Interview: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.Interview}>
      <Section background="subtle-gray">
        <Common2PaneSectionLayout width="no-limit">
          <SubSection>
            <DocumentStyle width="no-limit">
              {title ? <h2>{title}</h2> : <h2>診断事例インタビュー</h2>}
            </DocumentStyle>
          </SubSection>
          <SubSection>{children}</SubSection>
          <SubSection>
            <div className={styles.Interview_ButtonWrapper}>
              <Link to="/assessment/voice" className={styles.Interview_Button}>
                <Button buttonTheme="Default" buttonSize="Large">
                  すべての事例を見る
                </Button>
              </Link>
            </div>
          </SubSection>
        </Common2PaneSectionLayout>
      </Section>
    </div>
  );
};
