import React, { useMemo } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";

import type { Theme } from "~utils/theme";

import * as styles from "./index.module.scss";
import { graphql } from "gatsby";

type Props = {
  voice: GatsbyVoiceQueryFragment;
  theme?: Theme;
};

export const Voice: React.FC<Props> = (props) => {
  const { voice, theme } = props;
  const themeClassName = useMemo(() => {
    switch (theme) {
      case "FLATT_WEB":
        return "VoiceThumbnail__FLATT_WEB";
      case "KENRO":
        return "VoiceThumbnail__KENRO";
      default:
        return "VoiceThumbnail__FLATT_WEB";
    }
  }, [theme]);

  if (voice === null || voice === undefined) return null;

  return (
    <article className={clsx(styles.VoiceThumbnail, styles[themeClassName])}>
      <div className={styles.VoiceThumbnail_AvatarWrapper}>
        {voice.thumbnail?.gatsbyImageData && (
          <GatsbyImage
            image={voice.thumbnail?.gatsbyImageData}
            className={styles.VoiceThumbnail_Avatar}
            alt=""
          />
        )}
      </div>
      <div className={styles.VoiceThumbnail_About}>
        {voice.client && theme == "KENRO" && (
          <span className={styles.VoiceThumbnail_Company}>{voice.client}</span>
        )}
        {voice.title && (
          <h3 className={styles.VoiceThumbnail_Title}>{voice.title}</h3>
        )}
        {voice.industry &&
          voice.industry.map((industry) => {
            return (
              <span
                key={`industry-${industry?.slug}`}
                className={styles.VoiceThumbnail_Tag}
              >
                {industry?.name}
              </span>
            );
          })}
        {voice.plan &&
          voice.plan.map((plan) => {
            return (
              <span
                key={`plan-${plan?.slug}`}
                className={styles.VoiceThumbnail_Tag}
              >
                {plan?.name}
              </span>
            );
          })}
        {voice.freeTextTags &&
          voice.freeTextTags.map((tag) => {
            return (
              <span key={`tag-${tag}`} className={styles.VoiceThumbnail_Tag}>
                {tag}
              </span>
            );
          })}
      </div>
    </article>
  );
};

export const query = graphql`
  fragment VoiceQuery on ContentfulVoice {
    title
    client
    thumbnail {
      gatsbyImageData
    }
    freeTextTags
    slug
    sortOrder
    date
    industry {
      slug
      name
    }
    plan {
      slug
      name
    }
  }
`;
